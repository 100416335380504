import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { SearchIcon } from 'icons/SearchIcon';
import { TimesIcon } from 'icons/TimesIcon';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { FormComponentDemo } from '../../docs/helpers/FormComponentDemo';
import { Select, NotifiedSelect } from '../index';
import { options } from './helpers/options';
import { variants, sizes } from './helpers/variants';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Form/Select" title="Select" mdxType="PageTitle" />
    <h2 {...{
      "id": "basic-select"
    }}>{`Basic select`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"yellow\"\n          property=\"value\"\n          type=\"select\"\n        >\n          <Select\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="yellow" property="value" type="select" mdxType="FormComponentDemo">
            <Select onChange={(event, value) => console.log(value)} options={options} variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "multiple-select"
    }}>{`Multiple select`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue={[\'yellow\', \'blue\']}\n          property=\"values\"\n          type=\"select\"\n        >\n          <Select\n            multiple\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue={['yellow', 'blue']} property="values" type="select" mdxType="FormComponentDemo">
            <Select multiple label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "labeled-select"
    }}>{`Labeled select`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"yellow\"\n          property=\"value\"\n          type=\"select\"\n        >\n          <Select\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="yellow" property="value" type="select" mdxType="FormComponentDemo">
            <Select label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "transitional-labeled-select"
    }}>{`Transitional labeled select`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"select\">\n          <Select\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="select" mdxType="FormComponentDemo">
            <Select transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "transitional-labeled-select-with-icons"
    }}>{`Transitional labeled select with icons`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"select\">\n          <Select\n            transitional\n            iconAfter={<TimesIcon size={8} />}\n            iconBefore={SearchIcon}\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="select" mdxType="FormComponentDemo">
            <Select transitional iconAfter={<TimesIcon size={8} mdxType="TimesIcon" />} iconBefore={SearchIcon} label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "select-with-placeholder"
    }}>{`Select with placeholder`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"select\">\n          <Select\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            placeholder={{ label: \'Choose a color\' }}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="select" mdxType="FormComponentDemo">
            <Select label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} placeholder={{
              label: 'Choose a color'
            }} variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "select-with-separate-list-and-view-placeholder"
    }}>{`Select with separate list and view placeholder`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"select\">\n          <Select\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            placeholder={{ optionLabel: \'(None)\', label: \'Choose a color\' }}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="select" mdxType="FormComponentDemo">
            <Select transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} placeholder={{
              optionLabel: '(None)',
              label: 'Choose a color'
            }} variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "select-with-list-placeholder-only"
    }}>{`Select with list placeholder only`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"select\">\n          <Select\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            placeholder={{ optionLabel: \'(None)\', label: undefined }}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="select" mdxType="FormComponentDemo">
            <Select transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} placeholder={{
              optionLabel: '(None)',
              label: undefined
            }} variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "select-with-disabled-placeholder"
    }}>{`Select with disabled placeholder`}</h2>
    <Playground __position={9} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"select\">\n          <Select\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            placeholder={{ label: \'Choose a color\', disabled: true }}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="select" mdxType="FormComponentDemo">
            <Select transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} placeholder={{
              label: 'Choose a color',
              disabled: true
            }} variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "disabled-select"
    }}>{`Disabled select`}</h2>
    <Playground __position={10} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"yellow\"\n          property=\"value\"\n          type=\"select\"\n        >\n          <Select\n            disabled\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="yellow" property="value" type="select" mdxType="FormComponentDemo">
            <Select disabled label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "read-only-select"
    }}>{`Read only select`}</h2>
    <Playground __position={11} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"yellow\"\n          property=\"value\"\n          type=\"select\"\n        >\n          <Select\n            readOnly\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="yellow" property="value" type="select" mdxType="FormComponentDemo">
            <Select readOnly label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "read-only-multiple-select"
    }}>{`Read only multiple select`}</h2>
    <Playground __position={12} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue={[\'yellow\', \'blue\']}\n          property=\"values\"\n          type=\"select\"\n        >\n          <Select\n            multiple\n            readOnly\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue={['yellow', 'blue']} property="values" type="select" mdxType="FormComponentDemo">
            <Select multiple readOnly label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "danger-select"
    }}>{`Danger select`}</h2>
    <Playground __position={13} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"yellow\"\n          property=\"value\"\n          type=\"select\"\n        >\n          <Select\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            validity=\"danger\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="yellow" property="value" type="select" mdxType="FormComponentDemo">
            <Select transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} validity="danger" variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "warning-select"
    }}>{`Warning select`}</h2>
    <Playground __position={14} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"yellow\"\n          property=\"value\"\n          type=\"select\"\n        >\n          <Select\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            validity=\"warning\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="yellow" property="value" type="select" mdxType="FormComponentDemo">
            <Select transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} validity="warning" variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "success-select"
    }}>{`Success select`}</h2>
    <Playground __position={15} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"yellow\"\n          property=\"value\"\n          type=\"select\"\n        >\n          <Select\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            validity=\"success\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="yellow" property="value" type="select" mdxType="FormComponentDemo">
            <Select transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} validity="success" variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "contrast-select"
    }}>{`Contrast select`}</h2>
    <Playground __position={16} __code={'<ThemeWrapper contrast>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          contrast\n          unwrapped\n          initialValue=\"yellow\"\n          property=\"value\"\n          type=\"select\"\n        >\n          <Select\n            contrast\n            id=\"demo-contrast-select\"\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            options={options}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo contrast unwrapped initialValue="yellow" property="value" type="select" mdxType="FormComponentDemo">
            <Select contrast id="demo-contrast-select" label="Super fantastic label" onChange={(event, value) => console.log(value)} options={options} variant={variant} mdxType="Select" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "notifications"
    }}>{`Notifications`}</h2>
    <Playground __position={17} __code={'<ThemeWrapper>\n  <Looper\n    list={[\'danger\', \'warning\', \'success\', \'primary\', \'neutral\']}\n    render={level => (\n      <Looper\n        list={variants}\n        render={variant => (\n          <Rhythm py={3}>\n            <FormComponentDemo\n              unwrapped\n              initialValue=\"yellow\"\n              property=\"value\"\n              type=\"select\"\n            >\n              <NotifiedSelect\n                label=\"Super fantastic label\"\n                level={level}\n                notification={`This is an example ${level} notification.`}\n                onChange={(event, value) => console.log(value)}\n                options={options}\n                variant={variant}\n              />\n            </FormComponentDemo>\n          </Rhythm>\n        )}\n      />\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={['danger', 'warning', 'success', 'primary', 'neutral']} render={level => <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
              <FormComponentDemo unwrapped initialValue="yellow" property="value" type="select" mdxType="FormComponentDemo">
                <NotifiedSelect label="Super fantastic label" level={level} notification={`This is an example ${level} notification.`} onChange={(event, value) => console.log(value)} options={options} variant={variant} mdxType="NotifiedSelect" />
              </FormComponentDemo>
            </Rhythm>} mdxType="Looper" />} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "sizes"
    }}>{`Sizes`}</h2>
    <Playground __position={18} __code={'<ThemeWrapper>\n  <Looper\n    list={sizes}\n    render={size => (\n      <Rhythm grouped my={3}>\n        <Looper\n          list={variants}\n          render={variant => (\n            <Rhythm key={variant} py={3}>\n              <FormComponentDemo unwrapped property=\"value\" type=\"select\">\n                <Select\n                  transitional\n                  iconBefore={SearchIcon}\n                  label={`Size ${size}`}\n                  onChange={(event, value) => console.log(value)}\n                  options={options}\n                  size={size}\n                  variant={variant}\n                />\n              </FormComponentDemo>\n            </Rhythm>\n          )}\n        />\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SearchIcon,
      TimesIcon,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Select,
      NotifiedSelect,
      options,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={sizes} render={size => <Rhythm grouped my={3} mdxType="Rhythm">
          <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
                <FormComponentDemo unwrapped property="value" type="select" mdxType="FormComponentDemo">
                  <Select transitional iconBefore={SearchIcon} label={`Size ${size}`} onChange={(event, value) => console.log(value)} options={options} size={size} variant={variant} mdxType="Select" />
                </FormComponentDemo>
              </Rhythm>} mdxType="Looper" />
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Select} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      